<template>
  <canvas
    class="VueChart"
    :width="width"
    :height="height"
  />
</template>

<script>
import 'chart.js/dist/Chart.min.css'
import Chart from 'chart.js'

Chart.platform.disableCSSInjection = true

export default {
  name: 'VueChart',

  props: {
    width: { type: Number, default: 100 },
    height: { type: Number, default: 100 },
    type: { type: String, required: true }, // e.g.: 'donut', 'pie'
    data: { type: Object, required: true },
    options: { type: Object, default: () => ({}) },
  },

  computed: {
    chartDependencies() {
      const { data, options } = this
      return { data, options }
    },
  },

  watch: {
    chartDependencies: {
      async handler() {
        if (!this.$chart) await this.$nextTick()
        this.redrawChart()
      },
      deep: true,
    },

    type() {
      console.error('chart type changes are not supported')
    },
  },

  created() {
    this.$chart = null
  },

  mounted() {
    this.$chart = new Chart(this.$el, {
      type: this.type,
      data: this.data,
      options: this.options,
    })
  },

  beforeDestroy() {
    this.$chart && this.$chart.destroy()
    this.$chart = null
  },

  methods: {
    redrawChart() {
      const { $chart, data, options } = this

      $chart.data = data
      $chart.options = options
      $chart.update()
      this.$emit('redrawn')
    },
  },
}
</script>
